import Bg from '@images/lcl-consol-page/BG2x.png';
import styled from 'styled-components';

import { Button as BaseButton } from '@atoms/Button';
import { Column as ColumnBase, Row as RowBase } from '@atoms/Grid';
import CustomerServiceSvg from '@images/24x7-Customer-Service.svg';
import EffortlessShippingSvg from '@images/Effortless-Shipping.svg';
import RealTimeTrackingSvg from '@images/Real-time-Tracking.svg';

const Wrapper = styled.div`
  background-image: url(${Bg});
  background-position: 50% 50%;
  background-size: cover;

  @media ${props => props.theme.query.mobile} {
    background-position: 40% 50%, 60% 0%;
    background-size: cover, 800px;
  }
`;

const Title = styled.h2`
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${props => props.theme.color.white};
  ${props => props.theme.fontWeight.demi};
  letter-spacing: 3px;
  ${props => props.theme.typo.displayXXL};

  @media ${props => props.theme.query.mobile} {
    ${props => props.theme.typo.displayL};
  }
`;

const SubTitle = styled.h3`
  color: ${props => props.theme.color.white};
  ${props => props.theme.fontWeight.book};
  margin-top: 20px;
  margin-bottom: 40px;
  ${props => props.theme.typo.displayL};

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.bodyL};
    margin-bottom: 30px;
  }

  @media ${props => props.theme.query.mobile} {
    ${props => props.theme.typo.bodyM};
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

const MainContentWrapper = styled.div`
  width: 80%;
  text-align: center;

  @media ${props => props.theme.query.mobile} {
    margin-top: 150px;
    margin-bottom: 100px;
  }
`;

const Button = styled(BaseButton)`
  justify-content: center;
  border-radius: 30px;
  padding: 15px 40px;
  ${props => props.theme.typo.bodyL};
  min-width: 300px;
  height: 60px;

  @media ${props => props.theme.query.max.tablet} {
    height: 50px;
    ${props => props.theme.typo.bodyS};
    min-width: unset;
  }

  @media ${props => props.theme.query.mobile} {
    height: 44px;
    padding: 12px 40px;
    text-align: center;
  }
`;

const LevelingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  @media ${props => props.theme.query.max.tablet} {
    text-align: center;
  }

  @media ${props => props.theme.query.mobile} {
    justify-content: flex-start;
  }
`;

const ButtonContainer = styled(LevelingContainer)`
  @media ${props => props.theme.query.mobile} {
    justify-content: center;
  }
`;

const Row = styled(RowBase)`
  width: 80%;
  max-width: 1152px;
  margin: 0 auto;

  @media ${props => props.theme.query.max.tablet} {
    width: 95%;
  }
`;

const Column = styled(ColumnBase)`
  @media ${props => props.theme.query.mobile} {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0 10px;
  }
`;

const Text = styled.span`
  color: ${props => props.theme.color.white};
  ${props => props.theme.typo.displayM};

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.bodyM};
  }
`;

const Icon = styled.i`
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  display: inline-block;

  @media ${props => props.theme.query.max.tablet} {
    width: 25px;
    height: 25px;
  }
`;

const CustomerService = styled(Icon)`
  background-image: url('${CustomerServiceSvg}');
`;

const EffortlessShipping = styled(Icon)`
  background-image: url('${EffortlessShippingSvg}');
`;

const RealTimeTracking = styled(Icon)`
  background-image: url('${RealTimeTrackingSvg}');
`;

export {
  Wrapper,
  Title,
  SubTitle,
  MainContentWrapper,
  Button,
  LevelingContainer,
  ButtonContainer,
  Row,
  Column,
  Text,
  CustomerService,
  EffortlessShipping,
  RealTimeTracking,
};
