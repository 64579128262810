import styled from 'styled-components';

import { Button as BaseButton } from '@atoms/Button';

const Wrapper = styled.section`
  background-color: ${props => props.theme.color.white};
`;

const Title = styled.h2`
  color: ${props => props.theme.color.g500};
  ${props => props.theme.fontWeight.demi};
  ${props => props.theme.typo.displayXL};
  text-align: center;
  padding: 100px 0 80px;

  @media ${props => props.theme.query.max.tablet} {
    padding: 70px 0;
    ${props => props.theme.typo.displayL};
  }

  @media ${props => props.theme.query.mobile} {
    width: 90%;
    margin: 0 auto;
    padding: 70px 5px 60px;
    ${props => props.theme.typo.displayM};
  }
`;

const Button = styled(BaseButton)`
  min-width: 300px;
  height: 60px;
  justify-content: center;
  border-radius: 30px;
  ${props => props.theme.typo.bodyL};

  @media ${props => props.theme.query.max.tablet} {
    height: 50px;
    ${props => props.theme.typo.bodyS};
    min-width: unset;
    padding: 15px 40px;
  }

  @media ${props => props.theme.query.mobile} {
    height: 44px;
    padding: 12px 40px;
  }
`;

const LevelingContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.query.max.tablet} {
    padding-bottom: 60px;
  }

  @media ${props => props.theme.query.mobile} {
    padding-bottom: 80px;
  }
`;

export { Wrapper, Title, Button, LevelingContainer };
