import { useTranslation } from '@infra/i18n';
import { findImageByPath } from '@utils/find-image-by-path';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';
import React from 'react';

import {
  Image,
  ImageStar,
  SubTitle,
  Title,
  Wrapper,
} from './styled-components';

const LclConsolAwardsSection = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { absolutePath: { regex: "/lcl-consol-awards-section/" } }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(quality: 100, width: 925)
            }
          }
        }
      }
    }
  `);

  const images = get(data, 'images.edges', []);

  const imageStar = findImageByPath(
    images,
    'lcl-consol-awards-section',
    'stars.png'
  );
  const starsFluid = get(imageStar, 'node.childImageSharp.gatsbyImageData');

  const imageWinners = findImageByPath(
    images,
    'lcl-consol-awards-section',
    'winners.png'
  );
  const winnersFluid = get(
    imageWinners,
    'node.childImageSharp.gatsbyImageData'
  );

  return (
    <Wrapper>
      <ImageStar image={starsFluid} alt="" />
      <Title>{t('lclConsol.awardsSection.logisticsTechnology')}</Title>
      <SubTitle>{t('lclConsol.awardsSection.byTransport')}</SubTitle>
      <Image image={winnersFluid} alt="" />
    </Wrapper>
  );
};

export { LclConsolAwardsSection };
