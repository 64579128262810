import styled from 'styled-components';

const WrapperNew = styled.div`
  position: relative;
  top: 5px;
  display: flex;
  height: 95px;
  margin-top: -10px;
  padding: 0 45px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.color.g200};
  box-shadow: ${props => props.theme.color.g300} -1px 0px 20px 0px;
  text-align: center;
  border-radius: 5px;

  @media ${props => props.theme.query.max.tablet} {
    padding: 0 25px;
  }

  @media ${props => props.theme.query.mobile} {
    width: 100%;
    position: static;
    height: auto;
    margin-top: 0;
    flex-direction: row;
    box-shadow: none;
    padding: 20px 75px 20px 25px;
  }
`;

const WrapperOld = styled.div`
  background-color: ${props => props.theme.color.g200};
  text-align: center;
  padding: 20px 16px 25px;

  @media ${props => props.theme.query.mobile} {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    justify-content: center;
  }
`;

export { WrapperNew, WrapperOld };
