import { CustomerTestimonial } from '@features/customer-testimonial';
import { VideoSection } from '@molecules/video-section';
import { AdvantagesSection } from '@organisms/advantages-section';
import { OurEnablers } from '@organisms/our-enablers';
import { TrafficStatisticsSection } from '@organisms/traffic-statistics-section';
import React from 'react';

import { LclConsolAwardsSection } from './LclConsolAwardsSection';
import { LclConsolHeaderSection } from './LclConsolHeaderSection';
import { LclConsolPricingSection } from './LclConsolPricingSection';

const LclConsolPage = () => {
  return (
    <>
      <LclConsolHeaderSection />
      <LclConsolPricingSection />
      <CustomerTestimonial />
      <AdvantagesSection />
      <VideoSection videoLink="https://youtu.be/d1KILW8mHbI" />
      <TrafficStatisticsSection />
      <OurEnablers />
      <LclConsolAwardsSection />
    </>
  );
};

export { LclConsolPage };
