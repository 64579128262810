import { useTranslation } from '@infra/i18n';
import { FromToLabel } from '@molecules/from-to-label';
import { Pricing } from '@molecules/pricing';
import PropTypes from 'prop-types';
import React from 'react';

import {
  ArrowText,
  Container,
  FromToContainer,
  FromToWrapper,
  Link,
  Text,
  Wrapper,
} from './styled-components';

const PricingRow = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {data.map(
        ({
          fromToLabel: { from, to },
          pricing: { oldPricing, newPricing },
          link: { text, url },
        }) => {
          return (
            <Wrapper key={`row-${from}-${to}`}>
              <FromToWrapper>
                <FromToContainer>
                  <FromToLabel
                    from={<Text>{t(from)}</Text>}
                    to={<Text>{t(to)}</Text>}
                    arrow={<ArrowText>→</ArrowText>}
                  />
                </FromToContainer>
              </FromToWrapper>

              <Pricing
                type="old"
                label={t(oldPricing.label)}
                value={t(oldPricing.value)}
              />
              <Pricing
                label={t(newPricing.label)}
                value={t(newPricing.value)}
              />

              <Link to={url} aria-label={t(text)}>
                {t(text)} →
              </Link>
            </Wrapper>
          );
        }
      )}
    </Container>
  );
};

PricingRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export { PricingRow };
