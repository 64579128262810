import { LayoutSimplified } from '@features/layouts/layout-simplified';
import { LclConsolPage } from '@features/lcl-consol-page';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import React from 'react';

const LclConsol = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutSimplified locale={locale} location={location}>
      <SEO location={location} isNoIndex isNoFollow lang={locale} />
      <LclConsolPage />
    </LayoutSimplified>
  );
};

LclConsol.propTypes = {
  ...gatsbyPageProps,
};

export { LclConsol };
export default LclConsol;
