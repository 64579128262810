import styled from 'styled-components';

const PricingLabel = styled.div`
  ${props => props.theme.typo.bodyS};
  color: ${props => props.theme.color.g400};

  @media ${props => props.theme.query.mobile} {
    padding-right: 10px;
  }
`;

export { PricingLabel };
