import PropTypes from 'prop-types';
import React from 'react';

import { Wrapper } from './styled-components';

const FromToLabel = ({ from, to, arrow }) => {
  return (
    <Wrapper>
      {from}
      {arrow || <span>→</span>}
      {to}
    </Wrapper>
  );
};

FromToLabel.propTypes = {
  from: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  to: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  arrow: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

FromToLabel.defaultProps = {
  arrow: null,
};

export { FromToLabel };
