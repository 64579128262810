import styled from 'styled-components';

const PricingNewValue = styled.span`
  ${props => props.theme.typo.bodyL};
  ${props => props.theme.fontWeight.demi};
  color: ${props => props.theme.color.free};

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.bodyM};
  }
`;

const PricingOldValue = styled.span`
  ${props => props.theme.typo.bodyL};
  color: ${props => props.theme.color.g400};
  text-decoration: line-through;

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.bodyM};
  }
`;

export { PricingNewValue, PricingOldValue };
