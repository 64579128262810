import { Link } from '@atoms/link';
import { useTranslation } from '@infra/i18n';
import { ROUTES } from '@infra/routes';
import { PricingRow } from '@organisms/pricing-row';
import React from 'react';

import { Button, LevelingContainer, Title, Wrapper } from './styled-components';

const data = [
  {
    fromToLabel: {
      from: 'pricingRow.shanghai',
      to: 'pricingRow.nhavaSheva',
    },
    pricing: {
      oldPricing: {
        label: 'pricingRow.oldPrice',
        value: '$11/cbm',
      },
      newPricing: {
        label: 'pricingRow.freightStartingFrom',
        value: '$3/cbm',
      },
    },
    link: {
      text: 'aboutShipa.getQuote',
      url: `${ROUTES.QUOTE_FORM}?oc=CN&dc=IN`,
    },
  },
  {
    fromToLabel: {
      from: 'pricingRow.shanghai',
      to: 'pricingRow.chennai',
    },
    pricing: {
      oldPricing: {
        label: 'pricingRow.oldPrice',
        value: '$11/cbm',
      },
      newPricing: {
        label: 'pricingRow.freightStartingFrom',
        value: '$3/cbm',
      },
    },
    link: {
      text: 'aboutShipa.getQuote',
      url: `${ROUTES.QUOTE_FORM}?oc=CN&dc=IN`,
    },
  },
  {
    fromToLabel: {
      from: 'pricingRow.shanghai',
      to: 'pricingRow.hyderabad',
    },
    pricing: {
      oldPricing: {
        label: 'pricingRow.oldPrice',
        value: '$41/cbm',
      },
      newPricing: {
        label: 'pricingRow.freightStartingFrom',
        value: '$22/cbm',
      },
    },
    link: {
      text: 'aboutShipa.getQuote',
      url: `${ROUTES.QUOTE_FORM}?oc=CN&dc=IN`,
    },
  },
  {
    fromToLabel: {
      from: 'pricingRow.shanghai',
      to: 'pricingRow.delhi',
    },
    pricing: {
      oldPricing: {
        label: 'pricingRow.oldPrice',
        value: '$60/cbm',
      },
      newPricing: {
        label: 'pricingRow.freightStartingFrom',
        value: '$40/cbm',
      },
    },
    link: {
      text: 'aboutShipa.getQuote',
      url: `${ROUTES.QUOTE_FORM}?oc=CN&dc=IN`,
    },
  },
  {
    fromToLabel: {
      from: 'pricingRow.shanghai',
      to: 'pricingRow.bangalore',
    },
    pricing: {
      oldPricing: {
        label: 'pricingRow.oldPrice',
        value: '$41/cbm',
      },
      newPricing: {
        label: 'pricingRow.freightStartingFrom',
        value: '$22/cbm',
      },
    },
    link: {
      text: 'aboutShipa.getQuote',
      url: `${ROUTES.QUOTE_FORM}?oc=CN&dc=IN`,
    },
  },
];

const LclConsolPricingSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('lclConsol.pricingSection.howMuchDoesItCost')}</Title>
      <PricingRow data={data} />
      <LevelingContainer>
        <Link
          to={`${ROUTES.QUOTE_FORM}?oc=CN&dc=IN`}
          aria-label={t('heroSection.getStarted')}
        >
          <Button>{t('heroSection.getStarted')}</Button>
        </Link>
      </LevelingContainer>
    </Wrapper>
  );
};

export { LclConsolPricingSection };
