import PropTypes from 'prop-types';
import React from 'react';

import { PricingLabel } from '@atoms/PricingLabel';
import { PricingNewValue, PricingOldValue } from '@atoms/PricingValue';

import { WrapperNew, WrapperOld } from './styled-components';

const Pricing = ({ type, label, value }) => {
  let PricingValue;
  let Wrapper;

  switch (type) {
    case 'old':
      PricingValue = PricingOldValue;
      Wrapper = WrapperOld;
      break;

    default:
      PricingValue = PricingNewValue;
      Wrapper = WrapperNew;
  }

  return (
    <Wrapper>
      <PricingLabel>{label}</PricingLabel>
      <PricingValue>{value}</PricingValue>
    </Wrapper>
  );
};

Pricing.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

Pricing.defaultProps = {
  type: 'default',
};

export { Pricing };
