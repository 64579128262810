import { Link as LinkBase } from '@atoms/link';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 940px;
  margin: 0 auto;

  @media ${props => props.theme.query.max.tablet} {
    max-width: 728px;
  }

  @media ${props => props.theme.query.mobile} {
    padding: 0 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  max-height: 85px;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${props => props.theme.color.g200} -1px 0px 20px 0px;
  padding: 0px 20px;
  border-radius: 5px;

  @media ${props => props.theme.query.mobile} {
    max-height: none;
    margin-bottom: 20px;
    padding: 0;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const FromToWrapper = styled.div`
  flex-grow: 1;
  padding-left: 2.6%;

  @media ${props => props.theme.query.mobile} {
    width: auto;
    padding: 20px 25px;
  }
`;

const FromToContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Text = styled.span`
  ${props => props.theme.typo.bodyL};

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.bodyM};
  }
`;

const ArrowText = styled.span`
  ${props => props.theme.typo.bodyL};
  padding: 0 28px;
`;

const Link = styled(LinkBase)`
  color: ${props => props.theme.color.p300};
  ${props => props.theme.typo.bodyL};
  ${props => props.theme.fontWeight.demi};
  text-decoration: none;
  padding-left: 2.5%;
  padding-right: 2%;

  &:hover {
    text-decoration: underline;
  }

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.bodyM};
  }

  @media ${props => props.theme.query.mobile} {
    padding: 0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 40px;
    display: felx;
    justify-content: flex-end;
    text-align: right;
  }
`;

export {
  Container,
  Wrapper,
  FromToWrapper,
  FromToContainer,
  Text,
  ArrowText,
  Link,
};
